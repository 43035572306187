const SET_HCAPTCHA_COMPLETED = 'captcha/SET_HCAPTCHA_COMPLETED';

export const setChallengeCompleted = (completed) => ({
  type: SET_HCAPTCHA_COMPLETED,
  payload: { completed },
});

export const reducer = (state, action) => {
  switch (action.type) {
    case SET_HCAPTCHA_COMPLETED:
      return {
        challengeCompleted: action.payload.completed,
      };
    default:
      return state;
  }
};
