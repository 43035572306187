import { useEffect, useReducer, useState } from 'react';
import { reducer } from 'SharedComponents/hcaptcha/duck';
import { destroyCaptchaServices, isHCaptcha, loadCaptchaServices } from 'bv-services/captcha';

const useCaptchaLoader = () => {
  const [loading, setLoading] = useState(true);
  const [{ challengeCompleted }, dispatch] = useReducer(reducer, {
    challengeCompleted: !isHCaptcha,
  });

  useEffect(() => {
    loadCaptchaServices({ dispatch }).then(() => {
      setLoading(false);
    });

    return destroyCaptchaServices;
  }, []);

  return { loading, challengeCompleted };
};

export default useCaptchaLoader;
