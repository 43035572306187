import { uniq } from 'underscore';
import { v as bvVar } from 'bv';
import BaseCaptchaService from './base_captcha_service';
import CustomCaptchaService from './custom_captcha_service';
import GoogleCaptchaService from './google_captcha_service';
import HCaptchaCaptchaService from './hcaptcha_service';
import InvisibleHCaptchaService from './invisible_hcaptcha_service';

const captchaStrategy = bvVar('captchaStrategy');

const Service = {
  custom_solution: CustomCaptchaService,
  disabled: BaseCaptchaService,
  google_recaptcha_v3: GoogleCaptchaService,
  hcaptcha: HCaptchaCaptchaService,
  invisible_hcaptcha: InvisibleHCaptchaService,
}[captchaStrategy];

export const isHCaptcha = captchaStrategy === 'hcaptcha';

export const isInvisibleHCaptcha = captchaStrategy === 'invisible_hcaptcha';

const captchaService = Service ? new Service() : null;
const customCaptchaService = new CustomCaptchaService();

export const getCaptchaService = ({ allowHCaptcha } = {}) => (
  !allowHCaptcha && (isHCaptcha || isInvisibleHCaptcha)
    ? customCaptchaService
    : captchaService);

const captchaServices = uniq(
  [true, false].map((bool) => getCaptchaService({ allowHCaptcha: bool })),
);

export const loadCaptchaServices = (opts) => Promise.all(
  captchaServices.map((service) => service.load(opts)));

export const destroyCaptchaServices = () => captchaServices.forEach((service) => service.destroy());
